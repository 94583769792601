@import '../../../styles/variables.scss';

.TopicIndicatorsListMobile{

  .topic-breadcrumb{
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.25px;

    .indicator-name{
      color: $black-two;
    }

    .indicator-label{
      color: $denim-blue;
    }
  }

  .topic-title{
    font-size: 24px;
    color: $black-two;
    font-weight: 600;
  }

  .topic-subtitle{
    font-size: 24px;
    color: $black-two;
    font-weight: 500;
  }

  .topic-text{
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
  }

}

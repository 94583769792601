@import '../../../styles/variables.scss';

.TopicIndicatorsMenuLeft{
  
  .subtopic-name{
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 2px;
    color: #3A4A9F;
    text-transform: uppercase;
  }

  .topic-title{
    font-weight: 400;
    text-transform: capitalize;
    line-height: 1.2;
    font-size: 13px;
    margin-left: 12px;
    color: #8C8F93;
  }

  .topic-title:hover, .topic-title-active{
    color: #6B6D6F;
    font-weight: 600;
    margin-left: 12px;
    text-transform: capitalize;
    text-decoration: none;
  }

}

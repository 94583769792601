//Colors

$color3: #002d74;
$black: #282828;
$white: #e5e5e5;
$warm-gray: #8c8c8c;
$azul: #2a7de1;
$pinkish-orange: #f26852;
$brown-gray: #b4b4b4;
$very-light-pink: #d8d8d8;
$jade-green: #24a249;
$black-two: #000000;
$light-grey-blue: #9ca4cf;
$white-two: #ffffff;
$warm-grey-40: rgba(170, 121, 121, 0.4);
$turquoise-blue: #00a9c5;
$very-light-pink: #f3f3f3;
$turquoise-blue-40: rgba(0, 169, 197, 0.4);
$denim-blue: #3a4a9f;

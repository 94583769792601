@import '../../../styles/variables.scss';
.IndicatorChartDetail{
  // background-color: $very-light-pink;

  .filter-years {
    font-size: 11px;
    font-weight: 600;
    border-radius: 4px;
    border: solid 1px $brown-gray;
    color: $brown-gray;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    background-color: $very-light-pink;
  }

  .y-axis{
    min-width: 24px;
    font-family: 'Montserrat';
    fill: $warm-gray;
    font-size: 12px;
    text-transform: uppercase;
  }

  .x-axis{
    text-align: center;
    min-height: 24px;
    color: $warm-gray;
    font-size: 12px;
    text-transform: uppercase;
  }

  .filter-years:hover, .active-filter{
    color: $black;
    border: solid 1px $black;
  }

  .compare-county{
    border: solid 1px $brown-gray;
    border-radius: 2px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: $brown-gray;
    padding: 8px;
    background-color: $very-light-pink;
  }

  .compare-county:hover{
    color: $black;
    border: solid 1px $black;
  }

  .tab-item{
    font-size: 12px;
    font-weight: 600;
    padding-top: 4px;
    font-stretch: normal;
    text-transform: uppercase;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $brown-gray;
    width: 225px;
    border-bottom: 1px solid $brown-gray;
  }

  .dropdown-menu{
    transform: translate3d(0px, 35px, 0px) !important;
    height: 400px;
    width: 200px;
    overflow-y: scroll;
    top:0 !important;
  }

  .dropdown-item{
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: $brown-gray;
  }

  .tab-selected{
    color: $black;
    background-color:#EAEFF6;
    height:25px;
    border-bottom: 2px solid $brown-gray;
  }
  
  .tab-item:hover{
    color: $black;
    background-color:#EAEFF6;
    height:25px;
  }

  .last-data{
    font-size: 24px;
    font-weight: 400;
    font-family: Montserrat;
    color: $black-two;
  }

  .last-datum-value{
    font-size: 24px;
    font-weight: 300;
    font-family: Montserrat;
  }

  .date-picker-container-mobile{
    border: 1px solid $white-two !important;
    color: $white-two !important;
    background: $denim-blue !important;
  }

  .date-picker-container{
    background-color: #f4f4f4;
    padding: 5px;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    color: $brown-gray;
    border: 1px solid $brown-gray;
    border-radius: 5px;

    .react-datepicker__input-container input{
      border: 0;
      background-color: #f4f4f4;
      width: 45px;
    }

    .react-datepicker__input-container input:focus{
      outline: none;
    }

    .react-datepicker__header{
      background-color: $white-two !important;
      border: 0;
      font-size: 13px;
      font-family: Montserrat;
      font-weight: 600;
    }

    .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
      display: inline-block;
      width: 4rem;
      margin: 5px;
      border: 1px solid $brown-gray;
      border-radius: 5px;
      padding: 2px;
      color: $brown-gray;
    }

    .react-datepicker__month-text:hover, .react-datepicker__month--selected{
      border: 1px solid $black !important;
      color: $black !important;
      background-color: #f4f4f4;
    }

  }

  .dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.4);
  }

  .dot-active{
    background-color: #FFF;
  }

  .chart-name{
    color: $white-two;
    font-size: 13px;
    font-weight: 600;
  }


  .table-counties-top-bottom {
    td{
    font-size: 13px;
    padding: 3px;
    font-weight: 500;
    color: #707070;
  }
  .green {
    font-weight: 600;
    color: #b2c400;
  }

  .top-color{
    color: #00a9c5;
    font-weight: 600;
  }

  .bottom-color{
    color: #3a4a9f;
    font-weight: 600;
  }

  .bottom-color-mobile{
    color: #FFCA08;
    font-weight: 600;
  }

  .compare-color{
    color: #f26852;
    font-weight: 600;
  }

  .torquise{
    font-weight: 600;
    color: $turquoise-blue;
  }
  }

  .percent-figure{
    font-size: 72px;
    color: $pinkish-orange;
    font-weight: bold;
    text-align: center;
  }

}

@import "~bootstrap/scss/bootstrap";
@import "variables.scss";
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900&display=swap');

body{
  font-family: 'Montserrat';
}

.pointer{
  cursor: pointer;
}

.w-70{
  width: 70%;
}



.hex {

  @keyframes dash {
    to {
      stroke-dashoffset: 153;
    }
  }

  stroke-dasharray: 153;
  stroke-linecap: round;
  stroke-dashoffset: 0;
  animation: dash 2s linear infinite;
}


.hex2 {

  @keyframes dash {
    to {
      stroke-dashoffset: 306;
    }
  }
  stroke-dasharray: 306;
  stroke-linecap: round;
  stroke-dashoffset: 0;
  animation: dash 2s linear infinite;
}

.compare-county{
  border: solid 1px $brown-gray;
  border-radius: 2px;
  font-size: 11px;
  width: 280px;
  font-weight: 600;
  text-transform: uppercase;
  color: $warm-gray;
  padding: 8px;
  background-color: #f4f4f4;
}

.compare-county-white{
  background-color: $white-two;
}

.compare-county:hover{
  border: solid 1px $brown-gray;
  background-color: #f4f4f4;
  color: $brown-gray;
}

.text-pinkish{
  color: $pinkish-orange !important;
}

.text-torquise {
  color: $turquoise-blue !important;
}

.compare-county-list{
  width: 280px;
  border-radius: 3px;
  top: 33px !important;
  margin-top: 0px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px $brown-gray;
  background-color: $white-two;
}

.compare-county-item{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: $black-two;
}

.compare-county-item:active, .compare-county:active{
  background-color: $azul;
}

.compare-county-toggle{
  background-color: $azul;
}

.compare-county::after{
  display: none;
}

.county-selected{
  color: $pinkish-orange;
}

.text-pinkish{
  color: $pinkish-orange;
}

.text-torquise{
  color: $turquoise-blue;
}

.scroll-up-button{
  color: $white-two;
  padding: 15px;
  border-radius: 50%;
  object-fit: contain;
  width: 44px;
  height: 44px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $denim-blue;

  img {
    object-fit: contain;
    height: 24px;
    width: 24px;
  }

}

.bg-denim-blue{
  background-color: $denim-blue;
}

.bg-very-light-pink{
  background-color: $very-light-pink;
}

.bg-very-light-white{
  background-color: #fff;
}

.top-0{
  top: 0;
}

.date-picker-container{
  background-color: #f4f4f4;
  padding: 5px;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  color: $brown-gray;
  border: 1px solid $brown-gray;
  border-radius: 5px;
}

.date-picker-container:hover{
  border: 1px solid $black !important;
  color: $black !important;
  background-color: inherit;
}

.date-picker-container-active{
  border: 1px solid $black !important;
  color: $black !important;
}

@import '../../../styles/variables.scss';

.GoalBoxMobile{
  border-radius: 2px;
  border: solid 1px #CCC;

  .goal-number{
    font-size: 13px;
    font-weight: 600;
  }

  .goal-title{
    font-weight: 500;
    font-size: 13px;
  }

  .goal-link{
    font-size: 11px;
    letter-spacing: 0.25px;
    color: $turquoise-blue;
    font-weight: 600;
    text-transform: uppercase;
  }

}

@import '../../../styles/variables.scss';

.LandingMobile{
  height: calc(100vh - 56px);
  background-image: url('/texas-bg.jpg');
  background-size: cover;
  background-position: bottom center;

  .box-blue{
    padding-bottom: 80px;
    background-color: $denim-blue;
    opacity: 0.9;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .title-box{
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    color: $white-two;
    letter-spacing: 0.7px;

    b{
      font-weight: bold;
    }
  }

  .subtitle{
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    color: $white-two;
    margin-bottom: 20px;
  }

  .introduction{
    color: $white-two;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.28px;
  }

}

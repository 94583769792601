@import '../../../styles/variables.scss';
.SelectorDetail{
  background-color: #e5e5e5;

  .dropdown-toggle {
    background-color: #e5e5e5;
    border-radius: 0;
    border: 0;
    color: $black;
    font-size: 13px;
    font-weight: 600;
    white-space:normal;
    height: 70px;
  }

  .dropdown-toggle::after{
    text-align: right;
    display: none;
  }

  .dropdown-menu{
    top:14px !important;
    border: none;
    width: 100%;
    border-radius: 0;
    transform: translate3d(0px, 51px, 0px) !important;
    background-color: $very-light-pink;
    box-shadow: 0 4px 6px 0 rgba(40, 40, 40, 0.5);
  }

  .dropdown-item{
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dropdown-item div{
    font-size: 13px;
    color: $brown-gray;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space:normal;
  }

  // .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus{
  //   box-shadow: none !important;
  // }
  //
  // .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{
  //   background-color: #e5e5e5 !important;
  //   color: #b4b4b4;
  // }

  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus{
    box-shadow: none;
  }

  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle{
    background-color: #e5e5e5;
    color: $black;
  }

}

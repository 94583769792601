@import '../../styles/variables.scss';

.IndicatorMobile{
  .top-indicator{
    font-size: 12px;
    font-weight: 600;
    color: $color3;

    .topic{
      text-transform: uppercase;
    }
  }

  .navigation-indicators{
    button{
      border-radius: 2px;
      border: 1px solid $light-grey-blue;
      background-color: $white-two;

      .label {
        color: $light-grey-blue;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 600;
      }

      a, a:hover{
        font-size: 14px;
        color: $black-two;
      }
    }
  }

  .intestation-indicator{
    .topic-name{
      font-size: 13px;
      letter-spacing: 0.25px;
      color: $denim-blue;
      font-weight: 600;
    }
    .indicator-title{
      font-size: 24px;
      color: $black-two;
      font-weight: 600;
    }
  }

  .indicator-body{
    .share-block{
      .label{
        font-size: 11px;
        color: $brown-gray;
        font-weight: 500;
      }
      .social{
        color: $denim-blue;
        font-size: 11px;
        font-weight: 600;
      }
    }
    .indicator-subtitle{
      font-size: 24px;
      color: $black-two;
      font-weight: 500;
    }
    .indicator-performance, .indicator-trend{
      font-weight: bold;
      font-size: 13px;
      letter-spacing: 0.2;

      .red {
        color: #BE4854;
      }

      .gray {
        color: #D4D7D9;
      }

      .green{
        color: #20B138;
      }
      .yellow{
        color: #FECD61;
      }
    }

    .border-performance-trend {
      border: 1px solid $very-light-pink;
      width: 40%;
      height: 1px;
    }
    .indicator-description,.indicator-performance-description, .indicator-trend-description{
      color: $black-two;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    .indicator-paragraph{
      color: $warm-gray;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      border-left: 1px solid $warm-gray;
      padding-left: 10px;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }

    .frequency{
      .label{
        color: $warm-gray;
        font-size: 11px;
        font-family: Montserrat;
        text-transform: uppercase;
        font-weight: 600;
      }
      .frequency-value{
        color: $black-two;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .source{
      .label{
        color: $warm-gray;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;
      }
      .source-value{
        color: $black-two;
        font-size: 13px;
        font-weight: 500;
        overflow-wrap: break-word;
        word-wrap: break-word;
      }
    }
  }
  .menu-bottom{
    a{
      font-family: Montserrat;
      font-size: 20px;
      color: $brown-gray;
      letter-spacing: 0.25px;
      font-weight: 500;
    }
    a.active{
      color: $black-two;
    }
  }
}

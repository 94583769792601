@import '../../../styles/variables.scss';

.IndicatorLinkBoxMobile{
  background-color: #d8dbec;
  border-radius: 2px;

  .indicator-title{
    color: $black;
    font-size: 13px;
    font-weight: 600;
  }

  .indicator-link{
    color: $denim-blue;
    font-size: 11px;
    letter-spacing: 0.25px;
    font-weight: 600;
  }
}

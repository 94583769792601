@import '../../../styles/variables.scss';
.MenuTopMobile{

  overflow-x: scroll;

  .menu-option{
    // border-radius: 2px;
    // border: solid 1px $very-light-pink;
    color: $brown-gray;
    white-space: nowrap;
    font-size: 13px;
    padding: 7px;
    font-weight: 600;
    border: solid 1px #b4b4b4;
    border-radius: 4px;
  }

  .menu-option:hover{
    color: #3d3d3d;
    text-decoration: none;
    border: solid 1px #3d3d3d;
  }

  .menu-option:active, .active, .menu-option:hover.active{
    color: #3a4a9f;
    text-decoration: none;
    border: solid 1px #3a4a9f;
    background-color: #3a4a9f11;
  }

  .active-question, .question-menu:hover{
    color: $azul;
    border: solid 1px #3a4a9f;
  }
}

@import '../../../styles/variables.scss';

.ExternalLinkBoxMobile{
  border-radius: 2px;
  border: solid 1px #CCC;

  .link-title{
    font-size: 13px;
    font-weight: 600;
    color: $black;
  }

  .external-link{
    font-size: 11px;
    letter-spacing: 0.25px;
    color: $turquoise-blue;
    font-weight: 600;
    text-transform: uppercase;
  }

}
